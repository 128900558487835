@font-face {
  font-family: 'verdana';
  src:
    url('Verdana.woff2') format('woff2'),
    url('Verdana.woff') format('woff'),
    url('Verdana.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'verdana-bold';
  src:
    url('Verdana-Bold.woff2') format('woff2'),
    url('Verdana-Bold.woff') format('woff'),
    url('Verdana-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'verdana-bold-italic';
  src:
    url('Verdana-BoldItalic.woff2') format('woff2'),
    url('Verdana-BoldItalic.woff') format('woff'),
    url('Verdana-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
