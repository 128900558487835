/*
.p-toast {
  opacity: 0.98;
}

.p-tieredmenu,
.p-tieredmenu-overlay {
  z-index: 9001 !important;
}

.p-button {
  &:disabled {
    background-color: #b3aeae;

    &:hover {
      background-color: #b3aeae;
    }
  }
}

.p-dialog .p-dialog-footer {
  padding-bottom: 0.5rem;
}

.p-datepicker {
  width: 250px;
  min-width: 250px !important;
  !*  left: 8px !important;
  top: 58px !important;*!

  .pi-chevron-right,
  .pi-chevron-right {
    font-size: 12px;
  }

  table {
    th {
      font-size: 14px;
    }

    td {
      padding: 0;
      font-size: 14px;

      span {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}



.p-multiselect-panel {
  .p-multiselect-items,
  .p-multiselect-item {
    text-wrap: initial;
  }
}


// Sobrescrever o CSS do componente LEGEND PrimeNG
.p-fieldset .p-fieldset-legend {
  border-radius: 5px;

  a {
    color: #2d3337 !important;
  }
}

.p-component {
  font-family: helvetica-regular, sans-serif;
}

!*Sobrescreve imputs do primeng*!
.p-calendar,
.p-dropdown,
.p-inputtext,
.p-multiselect {
  //height: 30px;
  //border-radius: 6px;

  ::placeholder {
    color: #808080;
  }
}

.p-inputnumber {
  .p-inputnumber-button-group {
    .p-button-icon-only {
      width: 1rem;
      height: 15px;

      .pi {
        font-size: 12px;
      }
    }
  }
}

.p-inputtextarea {
  height: auto;
}

.input-group {
  position: relative;

  label {
    color: #808080;
    font-size: 14px;
    font-weight: bold;
  }

  input:disabled {
    background-color: #e5e5e5;
  }
}

.required-symbol {
  font-size: 14px;
  color: red;
}

.checkbox-group {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  margin-left: 1rem;

  label {
    margin-left: 0.25rem;
  }
}

.input-group ~ .field-radiobutton {
  flex-wrap: inherit;
  align-content: flex-end;
}

.p-dropdown .p-dropdown-trigger {
  background-color: #2e78ee;
  border-color: #2e78ee;
  color: white;
  padding: 10px;
  margin-top: -1px;
  margin-right: -1px;
}

.p-dropdown .p-dropdown-label {
  padding: 0.25rem 0.5rem;
}

.p-multiselect {
  border-right: #2e78ee;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-multiselect-label,
.p-multiselect-item {
  font-size: 13px;
}

.p-multiselect .p-multiselect-trigger {
  background-color: #2e78ee;
  border-color: #2e78ee;
  color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-calendar-w-btn .p-datepicker-trigger {
  background-color: #2e78ee;
  border-color: #2e78ee;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  &:disabled {
    border-color: #b3aeae;
  }
}

.toggle-filters {
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 5px;
    font-size: 11px;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    font-size: 11px;
  }

  .p-fieldset {
    margin-right: 8px;
    margin-top: 8px;
  }
}

.drawer-filters-container {
  .p-drawer-header {
    background-color: $primary-color;
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-family: helvetica-bold, sans-serif;

    .p-drawer-close {
      color: white;
    }
  }

  .p-drawer-content {
    height: 100%;
  }

  .p-drawer-footer {
    padding: 0;
    border-top: 1px solid #e1e1e1;
    background-color: #f0f0f0;

    .btn-azul-padrao,
    .btn-roxo {
      font-size: 14px;
      height: 30px;
    }
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}


.dropdown-inside-grid {
  font-size: 14px;

  .p-multiselect .p-multiselect-trigger {
    background-color: transparent;
    border-right: 1px solid #ced4da;
    color: #707070;
  }

  .p-dropdown-label {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
  }

  .p-dropdown-trigger {
    background-color: transparent;
    color: #707070;

    .p-dropdown-trigger-icon {
      font-size: 12px;
    }
  }
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #d1dae1;
  border-color: #848688;
}

.p-inputtext:enabled:hover {
  border-color: #848688;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #d1dae1;
  border-color: #848688;
}

.mensagem-aviso {
  .p-component {
    font-size: 12px !important;
    font-family: verdana, sans-serif !important;

    a {
      color: #007dc1;
      font-weight: bold;
    }
  }

  .p-message .p-message-wrapper {
    padding: 8px 15px;
  }
}

.container-custom-upload {
  color: #707070;

  .p-fileupload {
    display: flex;

    .p-fileupload-buttonbar {
      background-color: transparent;
      border-bottom: 1px solid #dee2e6;
      border-right: none;

      .p-button-icon-left {
        font-size: 20px;
      }

      .p-button {
        background-color: #707070;
        border-color: #707070;
        font-size: 12px;
      }
    }

    .p-fileupload-content {
      background-color: transparent;
      display: flex;
      flex: 1;
      border-left: none;
      font-size: 14px;
      justify-content: flex-start;
      align-items: center;
      padding: 0;

      .p-progressbar {
        display: none;
      }
    }
  }
}
*/
.p-tooltip {
  font-size: 13px !important;
}
.drawer-filters-container {
  .p-drawer-header {
    background-color: #007dc1;
    color: white;
    font-size: 18px;
    font-weight: bold;
    font-family: helvetica-bold, sans-serif;

    .p-drawer-close-button {
      color: white;
    }

    .p-button-secondary {
      color: white;

      &:not(disabled):hover {
        color: white;
        background-color: #007dc1;
      }
    }
  }

  .p-drawer-content {
    height: 100%;
  }

  .p-drawer-footer {
    padding: 0;
    border-top: 1px solid #e1e1e1;
    background-color: #f0f0f0;

    .btn-azul-padrao,
    .btn-roxo {
      font-size: 14px;
      height: 30px;
    }
  }
}
