/* You can add global styles to this file, and also import other style files */
@import 'variables.scss';
@import 'assets/fonts/verdana/verdana.css';
@import 'assets/fonts/helvetica/helvetica.css';
@import 'material-icons/iconfont/material-icons.scss';
@import 'assets/css/primeng-override';
@import 'assets/css/botoes-override';

body {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0;
  color: #808080;
  overflow-y: hidden;
  font-family: helvetica-regular, sans-serif;

  * {
    &::selection {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: helvetica-bold, sans-serif;
}

.form-title {
  margin: 0;
  font-size: 18px;
  color: #007dc1;
  font-family: helvetica-bold, sans-serif;
}

.form-description {
  font-size: 14px;
  color: #707070;
  margin: 0;
}

.nfe-key-header-modal {
  margin-left: 5px;
  font-size: 20px;
  color: black;
  font-weight: bold;
}

/*----------------------------*/

.dx-dropdownbutton-popup-wrapper,
.dx-datagrid-column-chooser {
  z-index: 12000 !important;
}

.dx-overlay-wrapper,
.dx-popup-wrapper,
.dx-dropdowneditor-overlay,
.dx-dropdownbutton-popup-wrapper {
  z-index: 12000 !important;
}

/*.dx-dropdownbutton-popup-wrapper{
  z-index: 9000 !important;
}*/

/*--------------------------------- CSS para incluir a barra superior do modal de download de XML e PDF *-----------------------------------*/
#container-nfe-option {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 6px 12px;
  background-color: #f7f8f9;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;

  .vertical-line {
    border-left: 1px solid #cccccc;
    height: 100%;
    margin-right: 15px;
    margin-left: 20px;
  }

  .container-info {
    display: flex;
    align-items: center;

    .pi-info-circle {
      font-size: 20px;
      color: #27a5db;
    }

    .pi-info-circle {
      margin-top: 2px;
      margin-right: 10px;
    }
  }
}

/*---------------------------------SOBRESCREVE CSS DO TOAST DO PRIMENG *-----------------------------------*/

.p-toast-message-success {
  background: color-mix(in srgb, var(--p-green-50), transparent 0%) !important;
  border-color: #16a34a !important;

  .p-toast-detail {
    color: #16a34a !important;
  }

  .p-button-text {
    color: #16a34a;
  }

  .p-toast-summary {
    font-weight: bold;
  }
}

.p-toast-message-error {
  background: color-mix(in srgb, var(--p-red-50), transparent 0%) !important;
  border-color: #dc2626 !important;

  .p-toast-detail {
    color: #dc2626 !important;
  }

  .p-button-text {
    color: #dc2626;
  }

  .p-toast-summary {
    font-weight: bold;
  }
}

.p-toast-message-warn {
  background: color-mix(in srgb, var(--p-yellow-50), transparent 0%) !important;
  border-color: #ca8a04 !important;

  .p-toast-detail {
    color: #ca8a04 !important;
  }

  .p-button-text {
    color: #ca8a04;
  }

  .p-toast-summary {
    font-weight: bold;
  }
}

/*---------------------------------SOBRESCREVE CSS DA GRID*-----------------------------------*/

// Setar a fonte correta no elemento de agrupamento das grids
.dx-datagrid-group-panel .dx-group-panel-message {
  font-family: 'helvetica-regular', sans-serif;
  color: #979eaa;
  opacity: 0.59;
}

// Evitar que a barra de rolagem fique por cima do conteúdo/última linha da GRID
.dx-scrollable-content::after {
  margin-top: 10px;
}

// Deixar a borda inferior mais espessa, no cabeçalho da grid
.dx-datagrid-borders > .dx-datagrid-headers {
  border-bottom: 2px solid #ddd;
}

// Incluir uma borda inferior nas linhas da GRID
.dx-datagrid-rowsview .dx-row {
  border-top: 1px solid transparent;
  border-bottom: 1px solid #eaeaea;
}

.dx-datagrid .dx-row-alt.dx-row:not(.dx-row-removed) {
  border-bottom-color: #eaeaea;
}

.dx-datagrid {
  font-family: verdana, sans-serif;
  font-size: 11px;
}

.grid-container {
  min-height: 200px;
  margin-top: 6px;
  /*Boão de salvar as opções personalizadas da grid*/

  /*Sobrescreve checkbox de dentro do datagrid*/
  .dx-datagrid-rowsview
    .dx-select-checkboxes-hidden
    > tbody
    > tr
    > td
    > .dx-select-checkbox {
    display: inline-block !important;
  }

  .dx-datagrid-headers {
    font-weight: bold;
    background-color: #e6e6e6;
    color: #494949;

    .dx-column-lines:first-child {
      .dx-command-select,
      .dx-datagrid-drag-action {
        background-color: #e6e6e6;
      }
    }

    .dx-column-lines:first-child {
      .dx-command-expand {
        background-color: #e6e6e6;
      }
    }

    .dx-column-lines:last-child {
      background-color: white;

      .dx-datagrid-content-fixed {
        background-color: #e6e6e6;
      }

      .dx-command-select,
      .dx-command-edit {
        background-color: white;
      }
    }
  }

  .dx-datagrid-nowrap,
  .dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
    white-space: normal;
  }

  .dx-toolbar-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dx-toolbar-center {
    display: flex;
    position: relative;
    padding: 0;
  }

  .dx-toolbar-before {
    display: flex;
    position: relative;
    padding: 0;
  }

  .dx-toolbar-after {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0;
  }

  .dx-device-mobile .dx-toolbar .dx-toolbar-items-container {
    height: auto;
  }

  .dx-toolbar-item {
    max-width: initial;
  }

  .dx-toolbar .dx-toolbar-label {
    font-size: 14px;
  }

  .dx-toolbar .dx-toolbar-items-container {
    height: auto;
  }

  /*Sobrescreve estilo das linhas da grid*/
  .dx-datagrid-rowsview .dx-row td {
    //padding: 18px 4px !important;
    //text-align: left;
    text-transform: uppercase;
    color: #707070;
    border: none !important;
    //vertical-align: middle;
  }

  .dx-datagrid-search-panel {
    margin-left: 0;
  }

  /*Sobrescreve botão do dropdown das opções da grid*/
  .btn-dropdown-grid .dx-button {
    border: 1px solid #dddddd !important;
  }

  /*Botão mais opções da grid*/

  /*Retira a linha do top do body da grid*/
  .dx-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview,
  .dx-datagrid-rowsview.dx-datagrid-after-headers {
    border-top: none;
  }
}

.grid-horizontal-scroll {
  //max-width: 87vw;
}

.nfe-legend-button {
  .dx-button {
    border-color: #e38424;
    background-color: #e38424;

    &:hover {
      background-color: #e38424 !important;
    }

    .dx-button-content {
      display: flex !important;
      align-items: self-end !important;
    }
  }

  .dx-state-hover {
    background-color: #e38424;
    border-radius: 4px;
  }

  .dx-state-focused {
    background-color: #e38424;
    border-radius: 4px;
  }

  .dx-button-has-text .dx-button-content {
    padding: 5px 10px;
  }

  .dx-button-text,
  .dx-icon-right {
    color: white;
  }

  .pi-send {
    color: white;
    font-size: 12px;
    margin-right: 5px;
  }
}

.container-template-legend {
  font-family: helvetica-regular, sans-serif;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 !important;

  .container-item-legend {
    margin: 12px 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .nfe-cancelada {
    background-color: #ffd6d6;
    margin: 0 !important;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
  }

  .nfe-com-cce {
    background-color: #fff3cd;
    margin: 0 !important;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
  }
}

.container-template-legend:last-child {
  border-bottom: none;
}

.nfe-manifest-button {
  margin-right: 8px;

  .dx-state-hover {
    background-color: #36a3ba;
    border-radius: 4px;
  }

  .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-state-disabled
    .dx-button-content,
  .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-disabled
    .dx-button-content {
    opacity: 1;
  }

  .dx-state-disabled .dx-widget .dx-widget,
  .dx-state-disabled.dx-widget .dx-widget {
    opacity: 1;
    background-color: #c9c9c9;
    color: gray;
    border-color: #c9c9c9;
  }

  .dx-button-has-text .dx-button-content {
    padding: 5px 10px;
  }

  .dx-state-focused {
    background-color: #36a3ba;
    border-radius: 4px;
  }

  .dx-button {
    border-color: #36a3ba;
    background-color: #36a3ba;

    &:hover {
      background-color: #36a3ba;
    }

    .dx-button-content {
      display: flex !important;
      align-items: self-end !important;
    }

    .dx-button-text,
    .dx-icon-right {
      color: white;
    }
  }

  .pi-list {
    color: white;
    font-size: 12px;
    margin-right: 5px;
  }
}

.container-template-manifest {
  font-family: helvetica-regular, sans-serif;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 !important;

  .container-item-manifest {
    margin: 12px 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

/*-------------------------------------------------------------------------------------------*/

.dx-dropdownbutton-popup-wrapper
  .dx-list:not(.dx-list-select-decorator-enabled)
  .dx-list-item-content {
  padding: 0.25rem 0.5rem;
}

.dx-page-sizes,
.dx-pages {
  color: #707070;
}

.text-red {
  color: red;
}

.text-red-message {
  color: #c64c40;
}

.text-blue {
  color: #012882;
}

.row-nfe-canceled {
  td {
    background-color: #ffd6d6 !important;
  }
}

.row-nfe-with-cce {
  td {
    background-color: #fff3cd !important;
  }
}

.row-bold {
  td {
    font-weight: bold !important;
  }
}

.content-icon-message {
  display: flex;
  justify-content: center;

  .content {
    padding: 8rem;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      color: #0023e5;
      font-size: 45px;
      margin-right: 10px;
    }
  }
}

.container-button-filters-grid {
  display: flex;
  justify-content: center;
  padding: 7px 2px;
  margin-right: 8px;
  border: 1px solid #d7d7d7;
  font-size: 12px;

  .pi-filter-slash {
    margin-right: 4px;
    color: #36a3ba;
    font-size: 12px;
  }

  .p-tag {
    font-size: 11px;
    color: white;
    height: 16px;
    border-radius: 12px;
    background-color: #959595;
    padding: 8px;
  }
}

.select-all-rows-container {
  border: 1px solid #d7d7d7;
  font-size: 12px;
  padding: 5px;
  margin-right: 8px;

  .p-tag {
    font-size: 11px;
    color: white;
    height: 16px;
    border-radius: 12px;
    background-color: #959595;
    padding: 8px;
  }
}

.select-all-rows-container,
.container-button-filters-grid {
  .select-all-rows {
    color: #36a3ba;
    font-weight: bold;
    margin-right: 12px;
    cursor: pointer;

    &::selection {
      background-color: transparent;
    }
  }
}

.hr {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.submitted {
  .ng-invalid {
    border-color: red;

    .p-inputtext {
      border-color: red;
    }
  }
}

.container-footer-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  padding-top: 9px;

  .p-button {
    width: 100px;
  }
}

.container-active {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;

  .p-button:disabled {
    background-color: #b3aeae;
    border-color: #b3aeae;

    &:hover {
      background-color: #b3aeae;
      border-color: #b3aeae;
    }
  }

  .btn-thumbs {
    .p-button {
      height: 35px;
      width: 35px;
    }
  }
}

.container-cell-inactive {
  background-color: #c64c40;
  border: #c64c40;
  color: #fff;

  &:hover {
    background-color: #b12004 !important;
    border-color: #b12004 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem #ba442e;
  }
}

.required-info {
  font-size: 12px;
  color: #999999;
}

.row-market-analysis-gray {
  background-color: red;
}

.row-market-analysis-white {
  background-color: #ffffff;
}

.grid-container tr.dx-state-hover {
  font-weight: bold;
}

.dx-selection {
  font-weight: bold;
}

/*SOBRESCREVE ESTILO DO ÍCONE DA SETA PARA MOSTRAR SUBGRID*/
.grid-container {
  .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space,
  .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > tr
    > td.dx-datagrid-group-space {
    vertical-align: inherit;
    padding: 0;
  }
}

.container-progressbar {
  display: flex;
  flex-direction: column;

  .label-progressbar {
    margin-top: 5px;
    color: #959595;
    font-size: 9px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
}

.options-button-menu {
  .p-menuitem {
    font-size: 12px;
    font-family: helvetica-regular, sans-serif;
  }

  .p-menuitem-link {
    padding: 0.5rem 1rem !important;
    color: #707070 !important;
  }
}

.background-modal {
  z-index: 9000;
  animation: p-component-overlay-enter-animation 150ms forwards;
  animation-duration: 150ms;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  animation-name: new-animation-background-dialog;

  @keyframes new-animation-background-dialog {
    from {
      background-color: rgba(0, 0, 0, 0.4);
    }
    to {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

/*Sobrescreve estilo do calendário*/

.env-tag-hml {
  border-radius: 20px;
  background-color: #ce0303;
  color: white;
  padding: 10px 18px;
  font-size: 12px;
  text-transform: uppercase;
}

.env-tag-dev {
  border-radius: 20px;
  background-color: #06661c;
  color: white;
  padding: 10px 18px;
  font-size: 12px;
  text-transform: uppercase;
}

#comTomTicketChatWidget {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  right: initial !important;
  position: relative !important;
  min-width: 102px !important;
  bottom: initial !important;
  background-color: transparent !important;

  .tomticketChat-icon-status-widget {
    background-color: #5da02d !important;
    float: initial !important;
    position: relative !important;
    left: initial !important;
    top: initial !important;
    padding: 4px !important;
    border-radius: 8px !important;
    font-size: 4px !important;
  }

  .tomticketChat-status {
    padding: 0 !important;
    margin: 0 0 0 8px !important;
    font-weight: initial !important;
    font-family: helvetica-regular, sans-serif !important;
  }
}

.dx-pager .dx-pages {
  margin-right: 50px;
}

.default-border-page {
  border: 5px solid #f5f5f5;
  padding: 0 10px 10px 10px;
}

.subgrid-container .dx-datagrid-headers {
  font-weight: bold;
  background-color: #39677d;
  color: #494949;

  .dx-column-lines:first-child {
    .dx-command-select,
    .dx-datagrid-drag-action {
      background-color: #39677d;
      color: white;
    }
  }

  .dx-column-lines:first-child {
    .dx-command-expand {
      background-color: #39677d;
    }
  }

  .dx-column-lines:last-child {
    background-color: white;

    .dx-datagrid-content-fixed {
      background-color: #39677d;
    }

    .dx-command-select,
    .dx-command-edit {
      background-color: white;
    }
  }
}

.grid-container .dx-datagrid-rowsview .dx-row {
  .dx-command-select {
    text-align: center;
  }
}

.container-action-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
}

.container-buttons {
  display: flex;
}

.p-inputmask {
  width: 100%;
}

.p-tieredmenu-mobile {
  position: relative !important;
  top: -100vh !important;
  left: 0 !important;
  width: 100vw !important;
  display: flex !important;
  align-items: center !important;
  z-index: 9999 !important;
}
