.btn-azul-padrao,
.btn-azul-claro,
.btn-vermelho,
.btn-laranja,
.btn-verde,
.btn-roxo,
.btn-icone {
  display: flex;

  .p-button {
    font-size: 14px;
  }

  .p-button:disabled {
    background-color: #b3aeae !important;
    border-color: #b3aeae !important;

    &:hover {
      background-color: #b3aeae !important;
      border-color: #b3aeae !important;
    }
  }
}

.btn-icone {
  .p-button {
    font-size: 12px;
  }

  .material-icons-outlined {
    font-size: 20px;
  }
}

.btn-azul-padrao {
  .p-button {
    background-color: #002882;
    border-color: #002882;
    color: white;

    &:not(disabled):hover {
      background-color: rgba(#002882, 0.9);
      border-color: #002882;
    }
  }
}

.btn-laranja {
  .p-button {
    background-color: #e38424;
    border-color: #e38424;
    color: white;

    &:not(disabled):hover {
      background-color: #e38424;
      border-color: #e38424;
      color: white;
    }
  }
}

.btn-vermelho {
  .p-button {
    background-color: #d92806;
    border-color: #d92806;
    color: white;

    &:not(disabled):hover {
      background-color: #b12004;
      border-color: #b12004;
    }
  }
}

.btn-verde {
  .p-button {
    background-color: #00821d;
    border-color: #00821d;
    color: white;

    &:not(disabled):hover {
      background-color: #05621c;
      border-color: #00821d;
    }
  }
}

.btn-roxo {
  .p-button {
    background-color: #601757;
    border-color: #601757c7;

    &:not(disabled):hover {
      background-color: #601757c7;
      border-color: #601757c7;
    }
  }
}

.btn-azul-claro {
  .p-button {
    border-color: #007dc1;
    background-color: #007dc1;

    &:not(disabled):hover {
      background-color: #1895d9;
      border-color: #1895d9;
    }
  }
}

.btn-delete-grid {
  .p-button {
    border-radius: 4px;
    padding: 4px 6px;
    height: 26px;
    width: 26px;

    .pi-trash {
      font-size: 12px;
    }
  }

  .material-icons-outlined {
    font-size: 13px;
  }
}

.btn-tag-grid {
  .p-button {
    border-radius: 4px;
    width: auto;
    padding: 6px;
    font-size: 12px;

    .pi-tags {
      font-size: 12px;
    }
  }
}

.btn-cog-grid {
  .p-button {
    border-radius: 4px;
    width: auto;
    padding: 4px;
    background-color: white;
    border-color: #36a3ba;
    color: #36a3ba;
    font-size: 12px;

    &:not(disabled):hover {
      background-color: #36a3ba;
      border-color: #36a3ba;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 0 0.1rem #36a3ba;
    }

    .pi-tags {
      font-size: 12px;
    }
  }
}

.btn-save-grid {
  .p-button {
    border-radius: 4px;
    height: 26px;
    width: 26px;
    padding: 4px 6px;
    font-size: 12px;

    .pi-save {
      font-size: 12px;
    }
  }
}

.btn-save-grid:disabled,
.btn-delete-grid:disabled {
  background-color: #b3aeae !important;
  border-color: #b3aeae !important;
}

/*Botão mais opções da grid*/
.options-button-menu {
  .p-tieredmenu-item-link {
    font-size: 12px;
    font-family: helvetica-regular, sans-serif;
    padding: 0.5rem 1rem !important;
    color: #707070 !important;
  }
}
