/*Sizes*/
$breadcrumb-height: 45px;
$topbar-height: 65px;
$topbar-mobile-height: 110px;
$menu-header-height: 90px;
$menu-header-height-mobile: 145px;
$menu-width: 240px;
$footer-height: 40px;
$footer-height-mobile: 40px;

/* Colors*/
$submenu-hover-color: #dedede;
$text-default-color: #707070;
$primary-color: #007dc1;
$button-blue-color: #002882;
$topbar-color: #f0f0f0;
$menu-header-color: #f0f0f0;
$dashboard-color: #f9f9f9;
$white: #ffffff;
$menu-item-color: #f0f0f0;
$menu-item-hover-color: #dedede;
$menu-item-root-hover-color: #eff2f4;

/* Shadows*/
$menu-shadow: 10px 4px 12px rgba(0, 0, 0, 0.2);

/*Grid primeng breakpoints*/
$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$hd: 1280px !default;
