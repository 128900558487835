@font-face {
  font-family: 'helvetica-regular';
  src:
    url('helvetica.woff') format('woff'),
    url('helvetica.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-bold';
  src:
    url('helvetica-bold.woff') format('woff'),
    url('helvetica-bold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-bold-oblique';
  src:
    url('helvetica-bold-oblique.woff') format('woff'),
    url('helvetica-bold-oblique.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-compressed';
  src:
    url('helvetica-compressed.woff') format('woff'),
    url('helvetica-compressed.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-oblique';
  src:
    url('helvetica-oblique.woff') format('woff'),
    url('helvetica-oblique.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-rounded-bold';
  src:
    url('helvetica-rounded-bold.woff') format('woff'),
    url('helvetica-rounded-bold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}
